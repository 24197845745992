import Axios, { AxiosResponse } from "axios";
import Swal from 'sweetalert2';

import { IAddLocationRequest } from "./interfaces/IAddLocationRequest";
import { IEmail } from "./interfaces/IEmail";
import { ILocation } from "./interfaces/ILocation";
import { IMachine } from "./interfaces/IMachine";
import { ISearchResponse } from "./interfaces/iSearchResponse";
import { ISearchRequest } from "./interfaces/iSearchRequest";
import { ISubmission } from "./interfaces/ISubmission";
import { IUpdateLocationRequest } from "./interfaces/IUpdateLocationRequest";
import { GetAccessToken } from "./lib/AuthProvider";
import { ICoordinates } from "./interfaces/ICoordinates";
import { IPaginatedResults } from "./interfaces/IPaginatedResults";

const apiUrl = process.env.NODE_ENV === 'development' ?
    'https://localhost:7086' :
    'https://api.kegfinder.co.uk';

const apiClient = Axios.create({ baseURL: apiUrl });

const getErrorMessage = (e: any): string => {
    const errors = e.response?.data.errors;
    if (typeof errors == 'object') {
        const keys = Object.keys(errors);
        if (keys.length) {
            return errors[keys[0]][0];
        }
    }

    return 'Unknown error';   
}

const handleError = (e: any): Error => {
    const message = getErrorMessage(e);
    Swal.fire("Error", message, 'error');
    throw e;
}

export const AddLocation = async (request: IAddLocationRequest) => {
    const accessToken = await GetAccessToken();

    try {
        await apiClient.post<IAddLocationRequest>('/Locations', request, { headers: { authorization: accessToken } });
    } catch (e: any) {
        throw handleError(e);
    }
}

export const GetEmails = async (pageNumber = 1): Promise<IPaginatedResults<IEmail>> => {
    const accessToken = await GetAccessToken();

    try {
        const response = await apiClient.get<IPaginatedResults<IEmail>>('/Emails', { headers: { authorization: accessToken }, params: { pageNumber } });
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}

export const GetLocation = async (id: string): Promise<ILocation> => {
    const accessToken = await GetAccessToken();

    try {
        const response = await apiClient.get<ILocation>(`/Locations/${id}`, { headers: { authorization: accessToken } });
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}

export const GetMachines = async (): Promise<IMachine[]> => {
    try {
        const response = await apiClient.get<IMachine[]>('/Machines');
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}

export const GetSubmissions = async (): Promise<ISubmission[]> => {
    try {
        const accessToken = await GetAccessToken();
        const response = await apiClient.get<ISubmission[]>('/Submissions', { headers: { authorization: accessToken } });
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}

export const DeleteLocation = async (id: string): Promise<void> => {
    try {
        const accessToken = await GetAccessToken();
        await apiClient.delete(`/Locations/${id}`, { headers: { authorization: accessToken } });
    } catch (e: any) {
        throw handleError(e);
    }
}

export const DeleteSubmission = async (id: string): Promise<void> => {
    try {
        const accessToken = await GetAccessToken();
        await apiClient.delete(`/Submissions/${id}`, { headers: { authorization: accessToken } });
    } catch (e: any) {
        throw handleError(e);
    }
}

export const LookupPostcode = async (postcode: string): Promise<ICoordinates> => {
    try {
        const accessToken = await GetAccessToken();
        const response = await apiClient.get<ICoordinates>('/Postcode/Lookup', { headers: { authorization: accessToken }, params: { postcode }});
        return response.data;
    } catch(e: any) {
        throw handleError(e);
    }
}

export const Search = async (request: ISearchRequest): Promise<ISearchResponse> => {
    const accessToken = await GetAccessToken();

    try {
        var response = await apiClient.post<ISearchRequest, AxiosResponse<ISearchResponse>>('/Locations/Search', request, { headers: { authorization: accessToken } });
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}

export const UpdateLocation = async (id: string, request: IUpdateLocationRequest): Promise<ILocation> => {
    const accessToken = await GetAccessToken();

    try {
        var response = await apiClient.put<IUpdateLocationRequest, AxiosResponse<ILocation>>(`/Locations/${id}`, request, { headers: { authorization: accessToken } });
        return response.data;
    } catch (e: any) {
        throw handleError(e);
    }
}
