import { LatLngLiteral, Map as LeafletMap } from 'leaflet';
import { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { ToLatLngLiteral } from '../helpers/CoordinatesHelper';
import { ICoordinates } from '../interfaces/ICoordinates';

import { MapEventHandler } from './Map/EventHandler';
import { Spinner } from './Spinner';

type MapProps = {
    center?: ICoordinates;
    postcode?: string;
    markerPosition?: ICoordinates;
    onMarker?: (coords: ICoordinates) => void,
}

export const Map = ({ center, postcode, onMarker, markerPosition }: MapProps) => {
    const [map, setMap] = useState<LeafletMap>();

    useEffect(() => {
        if (center) {
            map?.setView(ToLatLngLiteral(center));
        }
    }, [map, center]);

    const handleMarkerClick = (latLng: LatLngLiteral) => {
        if (onMarker) {
            onMarker({ latitude: latLng.lat, longitude: latLng.lng });
        }
    }

    return (
        <div className="kfa-map">
            {!center && <Spinner></Spinner>}
            {center && (
                <>
                    {postcode && <p><strong>Postcode</strong>: <a href={`https://www.google.com/maps?q=${postcode}`} target="_blank" rel="noopener noreferrer">{postcode}</a></p>}
                    <MapContainer
                        center={ToLatLngLiteral(center)}
                        zoom={19}
                        whenCreated={setMap}>
                        <MapEventHandler onClick={handleMarkerClick}></MapEventHandler>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && <Marker position={ToLatLngLiteral(markerPosition)}></Marker>}
                    </MapContainer>
                </>
            )}
        </div>
    )
}