import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { UpdateLocation } from '../../ApiClient';

import { ILocation } from '../../interfaces/ILocation';
import { IMachine } from '../../interfaces/IMachine';
import { IUpdateLocationRequest } from '../../interfaces/IUpdateLocationRequest';

import { SubmitButton } from '../Buttons/SubmitButton';
import { SelectMachines } from './Inputs/SelectMachines';

import { Map } from '../Map';

type UpdateLocationFormProps = {
    location: ILocation;
    machines: IMachine[] | null;
    onUpdate: (location: ILocation) => void;
}

export const UpdateLocationForm = (props: UpdateLocationFormProps) => {
    const formik = useFormik<IUpdateLocationRequest>({
        initialValues: {
            name: '',
            postcode: '',
            machines: [],
        },
        onSubmit: async (values) => {
            const updatedLocation = await UpdateLocation(props.location.id, values);
            props.onUpdate(updatedLocation);
        }
    });

    const { resetForm, setValues } = formik;

    useEffect(() => {
        resetForm();
        setValues({
            comment: props.location.comment,
            name: props.location.name,
            postcode: props.location.postcode,
            machines: props.location.machines.map(x => x.id),
            coords: props.location.coords
        });
    }, [props, resetForm, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Location name</Form.Label>
                <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter location name"
                    required
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    value={formik.values.name} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                    name="postcode"
                    type="text"
                    placeholder="Enter postcode"
                    required
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    value={formik.values.postcode} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                    as="textarea"
                    name="comment"
                    placeholder="Enter comment"
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    value={formik.values.comment} />
            </Form.Group>

            <SelectMachines
                machines={props.machines}
                name="machines"
                value={formik.values.machines}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting} />

            <div className="mb-3">
                <Map
                    center={props.location.coords}
                    markerPosition={formik.values.coords}
                    onMarker={coords => formik.setFieldValue('coords', coords)}></Map>
            </div>

            <SubmitButton
                text="Update location"
                submitting={formik.isSubmitting} />
        </form>
    );
}