import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import { LookupPostcode } from '../../ApiClient';
import { FormatPostcode } from '../../helpers/PostcodeHelper';
import { ICoordinates } from '../../interfaces/ICoordinates';

interface IPostcodeSearchButtonProps {
    onLookup(postcode: string, coords: ICoordinates): void;
}

export const PostcodeSearchButton = ({ onLookup }: IPostcodeSearchButtonProps) => {
    const [modalOpen, setModalOpen] = useState(false);

    const showModal = () => setModalOpen(true);

    const closeModal = () => {
        formik.resetForm();
        setModalOpen(false)
    }

    const formik = useFormik({
        initialValues: {
            postcode: ''
        },

        onSubmit: async () => {
            const formattedPostcode = FormatPostcode(formik.values.postcode);
            const response = await LookupPostcode(formattedPostcode);
            onLookup(formattedPostcode, response);
            closeModal();
        }
    });

    return (
        <>
            <Button className="kf-button kf-button--postcode" onClick={showModal}>
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </Button>

            <Modal className="kf-modal kf-modal--postcode" show={modalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Postcode</Modal.Title>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="submitEmail">
                            <Form.Label>Enter postcode</Form.Label>
                            <Form.Control
                                name="postcode"
                                type="text"
                                placeholder="Enter postcode"
                                required
                                value={formik.values.postcode}
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange} />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" disabled={formik.isSubmitting} type="submit">
                            {formik.isSubmitting ? 'Searching...' : 'Search'}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};