import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { ICoordinates } from '../../interfaces/ICoordinates';

type GoogleMapsButtonProps = {
    coords: ICoordinates;
}

export const GoogleMapsButton = ({ coords }: GoogleMapsButtonProps) => (
    <Button className="text-light" variant="primary" href={`https://www.google.com/maps/search/?api=1&query=${coords.latitude},${coords.longitude}`} target="_blank">
        <FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon> View on Google Maps
    </Button>
);