import { LatLngLiteral } from 'leaflet';
import { useMapEvents } from 'react-leaflet';

interface IMapEventHandlerProps {
    onClick?(coords: LatLngLiteral): void
}

export const MapEventHandler = ({ onClick }: IMapEventHandlerProps) => {
    useMapEvents({
        click: (event) => {
            if (onClick) {
                onClick(event.latlng);
            }
        }
    });

    return null;
}