import { AddLocationForm } from "../components/Forms/AddLocationForm";
import { IMachine } from "../interfaces/IMachine";

type NewLocationPageProps = {
    machines: IMachine[] | null
}

export const NewLocationPage = ({ machines }: NewLocationPageProps) => (
    <>
        <h3>Manually add new location</h3>
        <AddLocationForm machines={machines}></AddLocationForm>
    </>
);