import { useEffect, useState } from 'react';

import { IEmail } from '../interfaces/IEmail';
import { Spinner } from '../components/Spinner';
import { Email } from '../components/Email';

import { GetEmails } from '../ApiClient';
import { IPaginatedResults } from "../interfaces/IPaginatedResults";

export const EmailsPage = () => {
    const [emails, setEmails] = useState<IPaginatedResults<IEmail> | null>(null);

    useEffect(() => {
        GetEmails().then(setEmails);
    }, []);

    const loadMore = () => {
        GetEmails(emails!.pageNumber + 1).then(x => setEmails({
            pageNumber: x.pageNumber,
            pageSize: x.pageSize,
            results: [...emails!.results, ...x.results],
            totalResults: x.totalResults
        }));
    }

    return (
        <>
            <h3>Emails{emails && ` (${emails.totalResults})`}</h3>
            {!emails && <Spinner/>}
            {emails?.results.map(x => <Email key={x.id} email={x}/>)}
            {emails && emails.totalResults === 0 && <p>No emails found.</p>}
            {emails && emails.totalResults > emails.results.length && (
                <button className="btn btn-primary" onClick={loadMore}>Load More</button>
            )}
        </>
    );
}