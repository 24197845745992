import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Navbar } from './components/Navbar';
import { IMachine } from './interfaces/IMachine';
import { UpdateLocationPage } from "./pages/UpdateLocation";
import { EmailsPage } from './pages/Emails';
import { LocationsPage } from "./pages/Locations";
import { NewLocationPage } from './pages/NewLocation';
import { SubmissionsPage } from './pages/Submissions';

import { GetMachines } from './ApiClient';
import { AzureAdClientId, AzureAdTenantId } from "./Constants";

const publicClientApplication = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${AzureAdTenantId}`,
    clientId: AzureAdClientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
});

export default function App() {
  const [machines, setMachines] = useState<IMachine[] | null>(null);

  useEffect(() => {
    GetMachines().then(setMachines);
  }, []);

  return (
    <MsalProvider instance={publicClientApplication}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router>
          <Navbar></Navbar>
          <div className="container-fluid py-3">
            <Routes>
              <Route path="/locations/:id/update" element={<UpdateLocationPage machines={machines} />} />
              <Route path="/locations" element={<LocationsPage />} />
              <Route path="/emails" element={<EmailsPage />} /> 
              <Route path="/new-location" element={<NewLocationPage machines={machines} />} />
              <Route path="/" element={<SubmissionsPage machines={machines} />} />
            </Routes>
          </div>
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
