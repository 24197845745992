import React from 'react';
import { Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export const Navbar = () => (
    <BootstrapNavbar collapseOnSelect bg="primary" variant="dark" expand="lg">
        <LinkContainer to="">
            <BootstrapNavbar.Brand>KegFinder Admin</BootstrapNavbar.Brand>
        </LinkContainer>
        <BootstrapNavbar.Toggle aria-controls="navbar-nav" />
        <BootstrapNavbar.Collapse id="navbar-nav">
            <Nav>
                <LinkContainer to="/locations">
                    <Nav.Link>Locations</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/emails">
                    <Nav.Link>Emails</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/new-location">
                    <Nav.Link>Manually Add New Location</Nav.Link>
                </LinkContainer>
            </Nav>
        </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
);