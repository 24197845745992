import Swal from "sweetalert2";

export const AreYouSure = async (text: string): Promise<boolean> => {
    const response = await Swal.fire({
        title: 'Are you sure?',
        text,
        icon: 'error',
        confirmButtonText: 'Yes',
        confirmButtonColor: 'red',
        showCancelButton: true
    });

    return response.isConfirmed;
}