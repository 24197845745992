import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ILocation } from '../interfaces/ILocation';
import { Spinner } from '../components/Spinner';
import { Location } from '../components/Location';
import { AreYouSure } from '../helpers/AlertHelper';

import { DeleteLocation, Search } from '../ApiClient';

export const LocationsPage = () => {
    const [locations, setLocations] = useState<ILocation[] | null>();
    const navigate = useNavigate();

    useEffect(() => {
        Search({}).then(response => setLocations(response.results));
    }, []);

    const handleDeleteClick = async (location: ILocation) => {
        if (await AreYouSure('Are you sure you wish to delete this location?')) {
            await DeleteLocation(location.id);
            setLocations(null);
            Search({}).then(response => setLocations(response.results));
        }
    }

    const handleUpdateClick = (location: ILocation) => {
        navigate(`/locations/${location.id}/update`)
    }

    return (
        <>
            <h3>Locations{locations && ` (${locations.length})`}</h3>
            {!locations && <Spinner />}
            {locations?.map(x => 
                <Location 
                    key={x.id}
                    location={x}
                    onDeleteClick={handleDeleteClick}
                    onUpdateClick={handleUpdateClick} />)}
        </>
    );
}