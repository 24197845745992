import { FormatDateAsDisplay } from '../helpers/DateHelper';
import { IEmail } from '../interfaces/IEmail';

import { Card } from './Card';

type EmailProps = {
    email: IEmail;
}

export const Email = ({ email }: EmailProps) => (
    <Card>
        From: <strong>{email.from}</strong><br/>
        To: <strong>{email.to}</strong><br/>
        Subject: <strong>{email.subject}</strong><br/>
        Sent At: <strong>{FormatDateAsDisplay(email.updatedDate)}</strong>
        <div className="my-3">
            <div className="p-3 bg-dark text-light">
                {email.message}
            </div>
        </div>
    </Card>
);