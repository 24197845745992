import { Button } from 'react-bootstrap';

import { FormatDateAsDisplay } from '../helpers/DateHelper';
import { GoogleMapsButton } from './Buttons/GoogleMapsButton';
import { ILocation } from '../interfaces/ILocation';

import { Card } from './Card';

type LocationProps = {
    location: ILocation;
    onDeleteClick: (location: ILocation) => void;
    onUpdateClick: (location: ILocation) => void;
}

export const Location = ({ location, onDeleteClick, onUpdateClick }: LocationProps) => {
    return (
        <Card>
            <h3>{location.name} ({location.postcode})</h3>

            <p>ID: <strong>{location.id}</strong></p>

            <p>
                {location.submitterName && location.submitterEmail && (
                    <>
                        Submitted by: <strong>{location.submitterName} {`<${location.submitterEmail}>`}</strong><br />
                    </>
                )}
                Updated at: <strong>{FormatDateAsDisplay(location.updatedDate)}</strong>
            </p>

            <strong>Machines kegs sold for:</strong>
            <ul>
                {location.machines.map(x => <li key={x.id}>{x.name}</li>)}
            </ul>

            {location.comment &&
                <p>
                    <strong>Comment:</strong>
                    <div className="bg-dark text-light p-3">{location.comment}</div>
                </p>
            }

            <div className="mb-3 mb-lg-0 d-flex">
                <Button className="mr-1" variant="info" onClick={() => onUpdateClick(location)}>Update</Button>
                <Button className="mr-1" variant="danger" onClick={() => onDeleteClick(location)}>Delete</Button>
                <GoogleMapsButton coords={location.coords}></GoogleMapsButton>
            </div>
        </Card>
    );
}