import classNames from 'classnames';

import { FormatDateAsDisplay } from '../helpers/DateHelper';
import { ISubmission } from "../interfaces/ISubmission";

type SubmissionProps = {
    model: ISubmission;
    selected: boolean;
    onClick: (submission: ISubmission) => void;
}

export const Submission = ({ model, onClick, selected }: SubmissionProps) => (
    <div 
        onClick={() => onClick(model)} 
        className={classNames({ 'bg-light': !selected }, { 'bg-primary': selected }, { 'text-light': selected }, 'p-3', 'mb-3')}
        role="button">
        <h4>{model.retailerName}</h4>
        Submitted by: <strong>{model.name} {`<${model.email}>`}</strong><br/>
        Updated at: <strong>{FormatDateAsDisplay(model.updatedDate)}</strong><br/>
        {model.comment && 
            <p>
                Comment:<br/>
                <div className="bg-dark text-light p-3">{model.comment}</div>
            </p>
        }
    </div>
);