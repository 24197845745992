import { Form } from "react-bootstrap";

import { IMachine } from "../../../interfaces/IMachine";
import { Spinner } from "../../Spinner";

type SelectMachinesProps = {
    disabled: boolean;
    machines: IMachine[] | null;
    name: string;
    onChange: any; // TODO: Correct type param, re-write to handle state better
    value: string[];
}

export const SelectMachines = (props: SelectMachinesProps) => (
    <Form.Group className="mb-3">
        <Form.Label>Which machines does this retailer sell kegs for?</Form.Label>
        {props.machines?.map(machine => (
            <Form.Check
                key={machine.id}
                type="checkbox"
                name={props.name}
                checked={props.value.includes(machine.id)}
                value={machine.id}
                label={machine.name}
                disabled={props.disabled}
                onChange={props.onChange} />
        )) ?? <Spinner />}
    </Form.Group>
);