import { useEffect, useState } from 'react';

import { ISubmission } from '../interfaces/ISubmission';
import { IMachine } from '../interfaces/IMachine';
import { Submission } from '../components/Submission';
import { GetSubmissions } from '../ApiClient';
import { AddLocationForm } from '../components/Forms/AddLocationForm';
import { Spinner } from '../components/Spinner';
import { Card } from '../components/Card';

type SubmissionsPageProps = {
    machines: IMachine[] | null;
}

export const SubmissionsPage = ({ machines }: SubmissionsPageProps) => {
    const [submissions, setSubmissions] = useState<ISubmission[] | null>(null);
    const [selectedSubmission, setSelectedSubmission] = useState<ISubmission | null>(null);

    useEffect(() => {
        GetSubmissions().then(setSubmissions);
    }, [])

    const handleSubmissionClick = (submission: ISubmission) => {
        setSelectedSubmission(submission);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 250);
    }

    const handleLocationUpdate = () => {
        setSubmissions(null);
        setSelectedSubmission(null);
        GetSubmissions().then(setSubmissions);
    }

    return (
        <div className="row">
            <div className="col-lg-5">
                <h3>Submissions</h3>
                {submissions?.length === 0 && 'No submissions'}
                {submissions?.map(x =>
                    <Submission
                        key={x.id}
                        model={x}
                        selected={x.id === selectedSubmission?.id}
                        onClick={handleSubmissionClick}></Submission>) ?? <Spinner />}
            </div>

            <div className="col-lg-7">
                {selectedSubmission && (
                    <Card>
                        <AddLocationForm 
                            machines={machines}
                            submission={selectedSubmission}
                            onSubmit={handleLocationUpdate}
                            onDelete={handleLocationUpdate} />
                    </Card>
                )}
            </div>
        </div>
    );
};