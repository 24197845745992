import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";

import { AreYouSure } from "../../helpers/AlertHelper";
import { FormatPostcode } from "../../helpers/PostcodeHelper";
import { IAddLocationRequest } from "../../interfaces/IAddLocationRequest";
import { IMachine } from "../../interfaces/IMachine";
import { ISubmission } from "../../interfaces/ISubmission";
import { PostcodeSearchButton } from "../Buttons/PostcodeSearchButton";
import { SelectMachines } from "./Inputs/SelectMachines";
import { SubmitButton } from "../Buttons/SubmitButton";
import { ICoordinates } from "../../interfaces/ICoordinates";
import { AddLocation, DeleteSubmission, LookupPostcode } from "../../ApiClient";

import { Map } from '../Map';

const CENTRAL_LONDON: ICoordinates = { latitude: 51.505, longitude: -0.09 };

type AddLocationFormProps = {
    submission?: ISubmission;
    machines: IMachine[] | null;
    onDelete?: () => void;
    onSubmit?: () => void;
}

export const AddLocationForm = ({ machines, submission, onDelete, onSubmit }: AddLocationFormProps) => {
    const [center, setCenter] = useState<ICoordinates>();
    const [deleting, setDeleting] = useState(false);

    const formik = useFormik<IAddLocationRequest>({
        initialValues: {
            coords: null,
            name: '',
            machines: [],
            postcode: ''
        },

        onSubmit: async (values, instance) => {
            try {
                await AddLocation(values);
                instance.resetForm();

                if (onSubmit) {
                    onSubmit();
                }
            } catch {
            }
        }
    });

    const { initialValues, resetForm, setValues } = formik;

    useEffect(() => {
        resetForm();
        if (submission) {
            setValues({
                ...initialValues,
                comment: submission.comment,
                name: submission.retailerName,
                machines: submission.machines.map(x => x.id),
                postcode: FormatPostcode(submission.retailerPostcode),
                submitterName: submission.name,
                submitterEmail: submission.email,
                submissionId: submission.id
            });
        }
    }, [submission, initialValues, resetForm, setValues]);

    useEffect(() => {
        if (submission) {
            LookupPostcode(submission.retailerPostcode).then(setCenter);
        } else {
            setCenter(CENTRAL_LONDON);
        }
    }, [submission]);

    const onPostcodeInputBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        const formattedPostcode = FormatPostcode(event.currentTarget.value);
        const coords = await LookupPostcode(formattedPostcode);
        setCenter(coords);
        formik.setFieldValue('postcode', formattedPostcode);
    }

    const onPostcodeLookup = (postcode: string, coords: ICoordinates) => {
        setCenter(coords);
        formik.setFieldValue('postcode', postcode);
    }

    const onDeleteSubmissionClick = async (): Promise<void> => {
        if (submission && await AreYouSure('Are you sure you wish to delete this submission?')) {
            setDeleting(true);

            try {
                await DeleteSubmission(submission.id);

                if (onDelete) {
                    onDelete();
                }

                setDeleting(false);
            } catch {
                setDeleting(false);
            }
        }
    }

    const formDisabled = formik.isSubmitting || deleting;

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Location name</Form.Label>
                <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter location name"
                    required
                    onChange={formik.handleChange}
                    disabled={formDisabled}
                    value={formik.values.name} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Location postcode</Form.Label>
                <Form.Control
                    name="postcode"
                    type="text"
                    placeholder="Enter location postcode"
                    required
                    onChange={formik.handleChange}
                    onBlur={onPostcodeInputBlur}
                    disabled={formDisabled}
                    value={formik.values.postcode} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                    as="textarea"
                    name="comment"
                    placeholder="Enter comment"
                    onChange={formik.handleChange}
                    disabled={formDisabled}
                    value={formik.values.comment} />
            </Form.Group>

            {submission && (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Submitter name</Form.Label>
                        <Form.Control
                            name="submitterName"
                            type="text"
                            placeholder="Submitter name"
                            required
                            onChange={formik.handleChange}
                            disabled={formDisabled}
                            value={formik.values.submitterName} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Submitter Email</Form.Label>
                        <Form.Control
                            name="submitterEmail"
                            type="email"
                            placeholder="Submitter email"
                            required
                            onChange={formik.handleChange}
                            disabled={formDisabled}
                            value={formik.values.submitterEmail} />
                    </Form.Group>
                </>
            )}

            <SelectMachines
                machines={machines}
                name="machines"
                value={formik.values.machines}
                onChange={formik.handleChange}
                disabled={formDisabled} />

            <div className="mb-3">
                <div className="mb-2">
                    <PostcodeSearchButton onLookup={onPostcodeLookup}></PostcodeSearchButton>
                </div>

                <Map
                    center={center}
                    markerPosition={formik.values.coords!}
                    onMarker={coords => formik.setFieldValue('coords', coords)}></Map>
            </div>

            <div className="d-flex justify-content-between">
                {submission && (
                    <Button variant="danger" disabled={formDisabled} onClick={onDeleteSubmissionClick}>
                        {deleting ? 'Please wait...' : 'Delete Submission'}
                    </Button>
                )}

                {!submission && (
                    <div></div>
                )}

                <SubmitButton
                    text="Add location"
                    disabled={formDisabled}
                    submitting={formik.isSubmitting} />
            </div>
        </Form>
    );
}