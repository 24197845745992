import { Button } from "react-bootstrap"

type SubmitButtonProps = {
    disabled?: boolean;
    submitting: boolean;
    text: string;
}

export const SubmitButton = (props: SubmitButtonProps) => (
    <Button variant="primary" size="lg" type="submit" disabled={props.submitting || props.disabled}>
        {props.submitting ? 'Please wait...' : props.text}
    </Button>
);