import { PublicClientApplication } from "@azure/msal-browser";

import { AzureAdClientId, AzureAdTenantId } from "../Constants";

export const AuthProvider = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${AzureAdTenantId}`,
    clientId: AzureAdClientId
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
});

export const GetAccessToken = async (): Promise<string> => {
  var response = await AuthProvider.acquireTokenSilent({
    account: AuthProvider.getAllAccounts()[0],
    scopes: [`api://${AzureAdClientId}/kegfinder.admin`]
  });

  return `Bearer ${response.accessToken}`;
}
