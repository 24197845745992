import { useEffect, useState } from 'react';

import { ILocation } from '../interfaces/ILocation';
import { IMachine } from '../interfaces/IMachine';
import { Spinner } from '../components/Spinner';

import { GetLocation } from '../ApiClient';
import { useParams } from 'react-router-dom';
import { UpdateLocationForm } from '../components/Forms/UpdateLocationForm';

type RouteParams = {
    id: string;
}

type UpdateLocationPageProps = {
    machines: IMachine[] | null;
}

export const UpdateLocationPage = (props: UpdateLocationPageProps) => {
    const { id } = useParams<RouteParams>();
    const [location, setLocation] = useState<ILocation | null>();

    useEffect(() => {
        GetLocation(id!).then(setLocation);
    }, [id]);

    return (
        <>
            {location ?
                <>
                    <h3>Update location: {location.name}</h3>
                    <UpdateLocationForm 
                        location={location}
                        machines={props.machines}
                        onUpdate={(updatedLocation) => setLocation(updatedLocation)}></UpdateLocationForm>
                </>
            : <Spinner></Spinner>}
        </>
    );
}