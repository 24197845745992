import React from 'react';

type CardProps = {
    children: React.ReactNode;
}

export const Card = ({ children }: CardProps) => (
    <div className="p-3 mb-3 bg-light">
        {children}
    </div>
);